// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import "./App.css"; // Import your CSS file
import PrivacyPolicy from "./pages/PrivacyPolicy"; // Privacy Policy component
import TermsOfService from "./pages/TermsOfService"; // Terms of Service component
import Home from "./pages/Home"; // Home component

function App() {
  return (
    <Router>
      <div className="app">
        {/* Header Section */}
        <Header />

        {/* Main Content with Margin */}
        <div className="content-container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </Routes>
        </div>

        {/* Bottom Navigation Bar */}
        <footer className="bottom-bar">
          <Link to="/privacy-policy" className="bottom-link">
            Privacy Policy
          </Link>
          <Link to="/terms-of-service" className="bottom-link">
            Terms of Service
          </Link>
        </footer>
      </div>
    </Router>
  );
}

const Header = () => {
  const navigate = useNavigate();

  return (
    <header className="app-header">
      <div className="header-left" onClick={() => navigate("/")}>
        <h1 className="header-title">Evention</h1>
      </div>
      <div className="header-right">
        <button className="get-app-button">Get the app</button>
      </div>
    </header>
  );
};

export default App;
