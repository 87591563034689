// src/pages/Home.js
import React from "react";

const Home = () => {
  return (
    <header className="header">
      <h1 className="main-heading">Find out what's happening</h1>
    </header>
  );
};

export default Home;
